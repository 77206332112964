import(/* webpackMode: "eager" */ "/app/front_end/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager" */ "/app/front_end/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/front_end/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/front_end/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/front_end/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/front_end/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/SourceSerifPro-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/SourceSerifPro-Regular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/SourceSerifPro-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/SourceSerifPro-Italic.woff\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/SourceSerifPro-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/SourceSerifPro-Bold.woff\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/SourceSerifPro-BoldItalic.woff2\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/SourceSerifPro-BoldItalic.woff\",\"weight\":\"700\",\"style\":\"italic\"}],\"variable\":\"--font-source-serif-pro\"}],\"variableName\":\"sourceSerifPro\"}");
;
import(/* webpackMode: "eager" */ "/app/front_end/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/inter_18pt-medium.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/inter_18pt-mediumitalic.ttf\",\"weight\":\"400\",\"style\":\"italic\"}],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/front_end/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/front_end/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/front_end/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/front_end/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/inter_variable.ttf\",\"weight\":\"100 700\",\"style\":\"normal\"}],\"variable\":\"--font-inter-variable\"}],\"variableName\":\"interVariable\"}");
;
import(/* webpackMode: "eager" */ "/app/front_end/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/league_gothic_variable.ttf\",\"variable\":\"--font-league-gothic\"}],\"variableName\":\"leagueGothic\"}");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider","TranslationsBannerProvider"] */ "/app/front_end/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/components/chunk_retry_script.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/components/global_modals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/components/theme_provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/contexts/auth_context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalSearchProvider"] */ "/app/front_end/src/contexts/global_search_context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/contexts/modal_context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/contexts/navigation_context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/contexts/polyfill.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/contexts/public_settings_context.tsx");
